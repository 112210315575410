<template>
  <div>
    <div class="main-content card p-1" v-if="loader === 100">
      <div class="row">
        <div class="col-md-8">
          <label>Nome</label>
          <input type="text" class="form-control" v-model="totem.nome" disabled />
        </div>
        <div class="col-md-4">
          <label>Video</label>
          <v-select
            :options="videos"
            :reduce="(videos) => videos.id"
            :label="'titulo'"
            v-model="totem.video"
            class="vselect"
            disabled
          ></v-select>
        </div>
        <div :class="totem.descanso ? 'col-md-3 mt-1' : 'col-md-4 mt-1'">
          <label>Nome da impressora</label>
          <input type="text" class="form-control" v-model="totem.impressora" disabled/>
        </div>
        <div class="form-group" :class="totem.descanso ? 'col-md-2' : 'col-md-4'">
          <br><br>
          <div class="custom-control custom-switch">
            <input
              v-model="totem.descanso"
              placeholder=""
              type="checkbox"
              class="custom-control-input"
              id="customSwitch2"
              disabled
            />
            <label class="custom-control-label" for="customSwitch2">
              Descanso
            </label>
          </div>
        </div>
        <div class="col-md-4 mt-1" v-if="totem.descanso">
          <label>Tempo até tela de descanso (segundos)</label>
          <input
            type="number"
            min="0"
            class="form-control"
            v-model="totem.tempo"
            disabled
          />
        </div>
        <div :class="totem.descanso ? 'col-md-3 mt-1' : 'col-md-4 mt-1'">
          <label>Tempo da mensagem (ms):</label>
          <input
            type="number"
            min="0"
            class="form-control"
            v-model="totem.timeout"
            disabled
          />
        </div>
        <div class="col-md-4 mt-1">
          <label>Arredondamento</label>
          <label class="ml-5">{{ totem.arredondamento }}px</label>
          <input
            type="range"
            v-model="totem.arredondamento"
            class="form-control"
            step="1"
            min="0"
            max="50"
            disabled
          />
        </div>
        <div class="col-md-4 mt-1">
          <label>Tamanho da fonte do header</label>
          <label class="ml-5">{{ totem.fonte_header }}vh</label>
          <input
            type="range"
            v-model="totem.fonte_header"
            class="form-control"
            step="0.1"
            min="0"
            max="12"
            disabled
          />
        </div>
        <div class="col-md-4 mt-1">
          <label>Tamanho da fonte do relógio</label>
          <label class="ml-5">{{ totem.fonte_relogio }}vh</label>
          <input
            type="range"
            v-model="totem.fonte_relogio"
            class="form-control"
            step="0.1"
            min="0"
            max="12"
            disabled
          />
        </div>
        <div class="col-md-3">
          <label>Disposição</label>
          <select class="form-control" v-model="totem.disposicao" disabled >
            <option :value="'0'">Bloco</option>
            <option :value="1">Lista</option>
            <option :value="2">Agendamento</option>
          </select>
        </div>
        <div class="col-md-3">
          <label>Cor do fundo</label>
          <input type="color" class="form-control" v-model="totem.cor" disabled />
        </div>
        <div class="col-md-2" v-show="totem.disposicao === 2">
          <br />
          <label
            class="btn btn-primary w-100"
            v-bind:class="{ disabled: !totem.biometria }"
            style="cursor: pointer"
          >
            <input type="checkbox" v-model="totem.biometria" class="d-none" disabled />
            Biometria
          </label>
        </div>
        <div :class="totem.disposicao === 2 ? 'col-md-2' : 'col-md-3'">
          <br />
          <label
            class="btn btn-primary w-100"
            v-bind:class="{ disabled: !totem.degrade }"
            style="cursor: pointer"
          >
            <input type="checkbox" v-model="totem.degrade" class="d-none" disabled />
            Degradê
          </label>
        </div>
        <div :class="totem.disposicao === 2 ? 'col-md-2' : 'col-md-3'">
          <br />
          <label
            class="btn btn-primary w-100"
            v-bind:class="{ disabled: !totem.header }"
            style="cursor: pointer"
          >
            <input type="checkbox" v-model="totem.header" class="d-none" disabled />
            Header
          </label>
        </div>
        <div class="col-md-12">
          <label>URL para impressão</label>
          <input type="text" class="form-control" v-model="totem.url_impressao" disabled/>
        </div>
        <div :class="totem.header ? 'col-md-12' : 'd-none'">
          <label>Mensagem central</label>
          <textarea
            class="form-control"
            rows="5"
            v-model="totem.mensagem_central"
            disabled
          ></textarea>
        </div>
        <div :class="totem.header ? 'col-md-3' : 'd-none'">
          <br />
          <label>Cor do fundo do header</label>
          <input type="color" class="form-control" v-model="totem.background_header" disabled />
        </div>
        <div :class="totem.header ? 'col-md-3' : 'd-none'">
          <br />
          <label>Cor do texto do header</label>
          <input type="color" class="form-control" v-model="totem.color_header" disabled />
        </div>
        <div :class="totem.header ? 'col-md-3' : 'd-none'">
          <br />
          <br />
          <label
            class="btn btn-primary w-100"
            v-bind:class="{ disabled: !totem.relogio }"
            style="cursor: pointer"
          >
            <input type="checkbox" v-model="totem.relogio" class="d-none" disabled />
            Relógio
          </label>
        </div>
        <div :class="totem.header ? 'col-md-9' : 'd-none'">
          <br />
          <label>Logo do totem</label>
          <input type="file" class="btn" name="logo" id="logo" disabled />
        </div>
        <div class="col-md-12">
          <br />
          <label>Imagem de descanso</label>
          <input
            type="file"
            class="btn"
            name="tela_descanso"
            id="tela_descanso"
            disabled
          />
        </div>
        <div
          :class="
            'col-md-12 mt-1 mb-1 d-flex ' +
            (totem.header ? '' : 'justify-content-center')
          "
          :style="
            'height: 75vh; background:' +
            (totem.degrade
              ? 'radial-gradient(' +
                totem.cor +
                ' 0%, ' +
                totem.cor +
                ' 30%, #ccc 100%)'
              : totem.cor) +
            '; flex-direction: column;'
          "
        >
          <div
            :class="
              totem.header ? 'row text-center align-items-center' : 'd-none'
            "
            :style="'background:' + totem.background_header + ';'"
            style="height: 15vh; width: 102.5%"
          >
            <div :class="totem.relogio ? 'col-md-3' : 'd-none'">
              <h5 :style="'color: ' + totem.color_header + '!important;'">
                Horário: 01/01/2000 00:00:00
              </h5>
            </div>
            <div :class="totem.relogio ? 'col-md-6' : 'offset-md-3 col-md-6'">
              <h2 :style="'color: ' + totem.color_header + '!important;'">
                {{ totem.mensagem_central }}
              </h2>
            </div>
            <div :class="totem.header ? 'col-md-3' : 'd-none'">
              <h1
                style="padding: 1vh;"
                :style="
                  'color: ' + totem.color_header + '!important;' +
                  'border: 1px solid' + totem.color_header + ';'
                "
              >
                LOGO
              </h1>
            </div>
          </div>
          <div class="row align-items-center justify-content-center" v-if="totem.disposicao != 2">
            <div
              :class="
                (totem.disposicao != 1 ? 'col-md-3 ' : 'col-md-8') +
                ' btn btn-primary d-flex justify-content-center align-items-center mt-1'
              "
              :style="
                'font-size: 5vh; border-radius:' +
                totem.arredondamento +
                'px;' +
                (totem.disposicao != 1 ? 'height: 25vh;' : 'height: 15vh;')
              "
            >
              Botão exemplo 1
            </div>
            <div
              :class="
                (totem.disposicao != 1 ? 'col-md-3 offset-md-1' : 'col-md-8') +
                ' btn btn-warning d-flex justify-content-center align-items-center mt-1'
              "
              :style="
                'font-size: 5vh; border-radius:' +
                totem.arredondamento +
                'px;' +
                (totem.disposicao != 1 ? 'height: 25vh;' : 'height: 15vh;')
              "
            >
              Botão exemplo 2
            </div>
            <div
              :class="
                (totem.disposicao != 1 ? 'col-md-3 offset-md-1' : 'col-md-8') +
                ' btn btn-success d-flex justify-content-center align-items-center mt-1'
              "
              :style="
                'font-size: 5vh; border-radius:' +
                totem.arredondamento +
                'px;' +
                (totem.disposicao != 1 ? 'height: 25vh;' : 'height: 15vh;')
              "
            >
              Botão exemplo 3
            </div>
          </div>
          <div class="row align-items-center justify-content-center" v-else>
            <div class="col-md-7">
              <div class="p-2 w-100 bg-dark d-flex align-items-center justify-content-center" style="min-height: 50vh;">
                <h1 class="text-light">Vídeo</h1>
              </div>
            </div>
            <div class="offset-1 col-md-4">
              <div class="row justify-content-center text-center" style="display: flex !important; align-content: center !important; min-height: 70vh;">
                <div class="mb-1">
                  <h4>Digite Seu CPF</h4>
                  <div class="input-group mb-1 w-100">
                    <input
                      type="text"
                      class="form-control w-100"
                      v-mask="'999.999.999-99'"
                    >
                  </div>
                </div>
                <div class="col-12 d-flex justify-content-center align-itens-center">
                  <button class="btn btn-secondary mr-1 mb-1 p-1"><b style="font-size: 2vh;">1</b></button>
                  <button class="btn btn-secondary mr-1 mb-1 p-1"><b style="font-size: 2vh;">2</b></button>
                  <button class="btn btn-secondary mr-1 mb-1 p-1"><b style="font-size: 2vh;">3</b></button>
                </div>
                <div class="col-12 d-flex justify-content-center align-itens-center">
                  <button class="btn btn-secondary mr-1 mb-1 p-1"><b style="font-size: 2vh;">4</b></button>
                  <button class="btn btn-secondary mr-1 mb-1 p-1"><b style="font-size: 2vh;">5</b></button>
                  <button class="btn btn-secondary mr-1 mb-1 p-1"><b style="font-size: 2vh;">6</b></button>
                </div>
                <div class="col-12 d-flex justify-content-center align-itens-center">
                  <button class="btn btn-secondary mr-1 mb-1 p-1"><b style="font-size: 2vh;">7</b></button>
                  <button class="btn btn-secondary mr-1 mb-1 p-1"><b style="font-size: 2vh;">8</b></button>
                  <button class="btn btn-secondary mr-1 mb-1 p-1"><b style="font-size: 2vh;">9</b></button>
                </div>
                <div class="col-12 d-flex justify-content-center align-itens-center">
                  <button class="btn btn-danger mr-1 mb-1 p-1"><b style="font-size: 1.7vh;"><i class="fas fa-eraser"></i></b></button>
                  <button class="btn btn-secondary mr-1 mb-1 p-1"><b style="font-size: 2vh;">0</b></button>
                  <button class="btn btn-success mr-1 mb-1 p-1"><b style="font-size: 1.7vh;"><i class="fas fa-check"></i></b></button>
                </div>
                <div class="col-md-12">
                  <button class="btn btn-primary btn-sm" style="font-size: 1.7vh;" v-show="totem.biometria">Biometria</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-12 text-right">
          <hr />
          <router-link to="/totens" class="btn btn-back">
            <i class="fa fa-arrow-left"></i> Voltar
          </router-link>
        </div>
      </div>
    </div>
    <div class="row justify-content-center mt-3" v-else>
      <div class="col-10 text-center">
        <div class="progress">
          <div
            class="progress-bar bg-primary"
            role="progressbar"
            :style="'width: ' + loader + '%;'"
            aria-valuemin="0"
            aria-valuemax="100"
          ></div>
        </div>
        <b style="font-size: 14pt">Carregando</b><br />
        <b style="font-size: 16pt; line-height: 12pt">{{ loader }}%</b>
      </div>
    </div>
  </div>
</template>
<script>
import BaseCrud from "../../layouts/Base/BaseCrud";
import vSelect from "vue-select";

export default {
  name: "imageUpload",
  data() {
    return {
      loader: 0,
      totem: {
        arredondamento: 4,
        cor: "#ffffff",
        background_header: "#ffffff",
        color_header: "#000000,"
      },
    };
  },
  computed: {},
  methods: {
    getTotens: function (id) {
      const self = this;
      const api = self.$store.state.api + "totens/" + id;

      axios
        .get(api)
        .then((response) => {
          self.totem = response.data.data[0];

          if(self.totem.tempo)
          {
            self.totem.descanso = 1;
          }

          self.setLoader(25);
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    getVideos: function () {
      const self = this;
      const api = self.$store.state.api + "videos?paginated=false";

      axios
        .get(api)
        .then((response) => {
          self.videos = response.data.data;

          self.setLoader(25);
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    setLoader: function (qtd) {
      const self = this;

      let load = 0;
      let interval = setInterval(function () {
        self.loader++;
        load++;

        if (load == qtd) {
          clearInterval(interval);
        }
      }, 15);
    },
  },
  mounted: function () {
    const self = this;
    let id = self.$route.params.id;

    self.setLoader(50);
    self.getVideos();

    if (id) {
      self.getTotens(id);
    } else {
      self.setLoader(25);
    }
  },
  components: {
    BaseCrud,
    vSelect,
  },
};
</script>
<style scoped>
.profile_user {
  text-align: center;
}
.photo {
  display: block;
  margin-left: 5px;
  width: 18%;
  border-radius: 10px;
  max-width: 400px;
  max-height: 400px;
  object-fit: cover;
}
.btn-user:hover,
.btn-user[aria-expanded="true"] {
  background: linear-gradient(to right, #000, #666);
  color: #fff !important ;
}
.photo-title {
  color: #000;
  font-size: 18px;
  display: block;
  margin-left: 5px;
  width: 18%;
}
</style>