<template>
  <div>
    <div class="main-content card" v-if="loader === 100">
      <div class="card-header p-1">
        <div class="row w-100">
          <div class="col-8">
            <b>Senha: {{ atendimento.prefixo }}{{ atendimento.senha }}&nbsp;</b>
            <span
            class="badge"
            :style="
                'background:' + atendimento.botao.cor +  ';' +
                'color:' + atendimento.botao.cor_texto + ';'
                "
            >
            {{ atendimento.botao.nome }}
          </span>
        </div>
        <div class="col-4 text-right p-0">
            <button class="btn btn-outline-primary btn-sm mr-1"  data-toggle="modal" data-target="#setor" v-if="!atendimento.data_fim && $store.state.user.perfil_id > 2">Alterar setor</button>
            <div class="modal fade" id="setor" tabindex="-1" role="dialog" aria-labelledby="setorLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
              <div class="modal-dialog" role="document">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title" id="setorLabel">Selecionar setor para enviar a senha:</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div class="modal-body text-center">
                    <v-select
                      :options="setores"
                      :reduce="(setores) => setores.id"
                      :label="'nome'"
                      v-model="atendimento_setor_atual.setor_id"
                      class="vselect"
                    ></v-select>
                  </div>
                  <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancelar</button>
                    <button type="button" class="btn btn-primary" data-dismiss="modal" @click="trocaSetor">Trocar setor</button>
                  </div>
                </div>
              </div>
            </div>
            <span :class="'badge badge-' + (atendimento.ativo ? 'secondary' : ( atendimento.finalizacao_id > 0 ? 'success' : 'danger'))">
              Status:
              {{
                (
                  atendimento.ativo ?
                    'Em atedimento' : (
                      atendimento.finalizacao_id === -1 ?
                        'Evadido' : (
                          atendimento.finalizacao_id === -2 ?
                            'Cancelado' :
                            'Finalizado'
                        )
                    )
                )
              }}
            </span>
          </div>
        </div>
      </div>
      <div class="card-body bg-secondary">
        <div class="row justify-content-center px-0 py-3">
          <div class="col-md-3 card d-flex justify-content-center align-items-center text-center mx-1 my-1">
            <h1>Senha<br>Retirada</h1>
          </div>
          <div class="col-md-4 row" v-for="setor in atendimento_setor">
            <div class="col-md-3 d-flex justify-content-center align-items-center" style="min-height: 100%">
              <i class="fas fa-arrow-right text-light" style="font-size: 30px; max-height: 35px"></i>
            </div>
            <div class="col-md-9 card d-flex justify-content-center text-center p-0 my-1">
              <div
                class="card-header justify-content-center p-0"
                :style="
                  'background:' + (setor.classificacao_risco_id ? setor.classificacao.cor : '#666') +
                  ';color: #fff;'
                "
              >
                <b style="padding: 4px 0;">{{ setor.setor.nome }} ({{ setor.status_text }})</b>
              </div>
              <div class="card-body">
                <b>Entrada no setor:</b><br>
                <span>{{ setor.data_entrada_f }}<br><br></span>
                <b v-if="setor.data_inicio_f">Inicio:<br></b>
                <span v-if="setor.data_inicio_f">{{ setor.data_inicio_f }}<br><br></span>
                <b v-if="setor.data_fim_f">Fim:<br></b>
                <span v-if="setor.data_fim_f">{{ setor.data_fim_f ? setor.data_fim_f  : 'Não finalizado' }}<br><br></span>
                <b v-if="setor.user_id">Atendido por:<br></b>
                <span v-if="setor.user_id">{{ setor.user.name ? setor.user.name  : 'Não finalizado' }}<br><br></span>
                <b>Classificação:<br></b>
                <b :style="'color: ' + (setor.classificacao_risco_id ? setor.classificacao.cor : '#666')">
                  {{setor.classificacao_risco_id ? setor.classificacao.nome : 'Não classificado'}}<br>
                </b>
              </div>
            </div>
          </div>
          <div class="col-md-1 d-flex justify-content-center align-items-center" style="min-height: 100%" v-if="!atendimento.ativo">
            <i class="fas fa-arrow-right text-light" style="font-size: 30px; max-height: 35px"></i>
          </div>
          <div class="col-md-3 card d-flex justify-content-center align-items-center text-center mx-1 py-3 my-1" v-if="!atendimento.ativo">
            <b>Senha finalizada:</b>
            <span>{{ atendimento.data_fim_f ? atendimento.data_fim_f : "Não finalizada" }}</span><br>
            <b>Finalização:</b>
            <span>
              {{
                atendimento.finalizacao_id > 0 ?
                  atendimento.finalizacao.nome :
                  (
                    atendimento.finalizacao === -1 ?
                      configuracao.tipo_pessoa + " Evadida" :
                      "Senha Cancelada"
                  )
              }}
            </span>
            <span v-if="atendimento.finalizacao_id === -2">
              <br>
              <b>Descrição do cancelamento:</b>
              <br>
              {{ cancelamento.texto }}
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="row justify-content-center mt-3" v-else>
      <div class="col-10 text-center">
        <div class="progress">
          <div class="progress-bar bg-primary" role="progressbar" :style="'width: ' + loader + '%;'" aria-valuemin="0" aria-valuemax="100"></div>
        </div>
        <b style="font-size: 14pt;">Carregando</b><br>
        <b style="font-size: 16pt; line-height: 12pt;">{{ loader }}%</b>
      </div>
    </div>
  </div>
</template>
<script>

import BaseCrud from "../../layouts/Base/BaseCrud";
import vSelect from "vue-select";

export default {
  name: "imageUpload",
  data() {
    return {
      configuracao: [],
      loader: 0,
      atendimento: {
        cor: "#ffffff"
      },
      atendimento_setor: [],
      cancelamento:{
        texto: ""
      },
      setores: [],
      atendimento_setor_atual: "",
    };
  },
  computed: {},
  methods: {
    getAtendimento: function (id) {
      const self = this;
      const api = self.$store.state.api + "atendimentos/" + id + "?with[]=botao&with[]=finalizacao";

      axios
        .get(api)
        .then((response) => {
          self.atendimento = response.data.data[0];

          if(self.atendimento.finalizacao_id == -2)
          {
            self.getPath(id);
            self.getCancelamento(id);
          }else{
            self.getPath(id);
          }
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    getCancelamento: function(id) {
      const self = this;
      const api = self.$store.state.api + "cancelamentos?atendimento_id=" + id;

      axios
        .get(api)
        .then((response) => {
          self.cancelamento = response.data.data[0];
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    getSetores: function(id) {
      const self = this;
      const api = self.$store.state.api + "setores?ativo=1";

      axios
        .get(api)
        .then((response) => {
          self.setores = response.data.data;

          self.setLoader(30);
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    getConfiguracao: function () {
      const self = this;
      const api = self.$store.state.api + "configuracoes/";

      axios
        .get(api)
        .then((response) => {
          self.configuracao = response.data.data[0];
          self.setLoader(30);
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    getPath: function(id) {
      const self = this;
      const api = self.$store.state.api + "atendimento_setor?atendimento_id=" + id +"&with[]=classificacao&with[]=setor&with[]=user";

      axios
        .get(api)
        .then((response) => {
          self.atendimento_setor = response.data.data;

          self.atendimento_setor_atual = self.atendimento_setor[self.atendimento_setor.length-1];
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    trocaSetor: function() {
      const self = this;
      const api = self.$store.state.api + "atendimento_setor/" + self.atendimento_setor_atual.id;

      self.$loading(true);
      self.atendimento_setor_atual._method = "PUT";

      self.atendimento_setor_atual.status = 1;
      self.atendimento_setor_atual.user_id = 0;
      self.atendimento_setor_atual.local_id = null;

      axios
        .post(api, self.atendimento_setor_atual)
        .then((response) => {
          self.$message(
            "Sucesso",
            `Informações guardadas com sucesso`,
            "success"
          );
          self.$loading(false);
          this.$router.push("/senhas");
        })
        .catch((error) => {
          self.$loading(false);
          self.$message(null, error.response.data, "error");
        });
    },
    setLoader: function(qtd) {
      const self = this;

      let load = 0; 
      let interval = setInterval(function(){      
        self.loader++;
        load++;

        if(load == qtd)
        {
          clearInterval(interval);
        }
      }, 15);
    },
  },
  mounted: function () {
    const self = this;
    let id = self.$route.params.id;

    self.setLoader(40);
    self.getConfiguracao();
    self.getSetores();

    if (id) {
      self.getAtendimento(id);
    }
  },
  components: {
    BaseCrud,
    vSelect,
  },
};
</script>
<style scoped>
.profile_user {
  text-align: center;
}
.photo {
  display: block;
  margin-left: 5px;
  width: 18%;
  border-radius: 10px;
  max-width: 400px;
  max-height: 400px;
  object-fit: cover;
}
.btn-user:hover,
.btn-user[aria-expanded="true"] {
  background: linear-gradient(to right, #000, #666);
  color: #fff !important ;
}
.photo-title {
  color: #000;
  font-size: 18px;
  display: block;
  margin-left: 5px;
  width: 18%;
}
</style>